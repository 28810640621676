body{
  font-size: 10px;
}
.form-control { 
  font-size: 10px;
}
/* .form-control:focus {
  font-size: 20px;
} */
.body-login {
    margin: 0;
    padding: 0;
    background-color: #0f0f1a;
    color: #fff;
    font-family: Arial, sans-serif;
  }
  
  .container-login {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
  }
  
  .title {
    text-align: center;
    font-size: 24px;
    margin-bottom: 20px;
  }
  
  .card {
    width: 300px;
    background-color: #1e213a;
    padding: 20px;
    border-radius: 10px;
    border-top: 4px solid #19d4ca;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
  }
  
  .form-login {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  .btn-primary, .btn-danger{
    font-size: 10px;
  }
  /* .btn-primary:hover, .btn-danger:hover{
    font-size: 20px;
  } */
  .input-login {
    padding: 10px;
    border: none;
    background-color: transparent;
    border-bottom: 1px solid #ccc;
    color: #fff;
    transition: box-shadow 0.3s;
  }
  
  .input-login:focus {
    box-shadow: 0 0 10px #19d4ca;
  }
  
  .buttons {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 10px;
  }
  
  .login-button,
  .register-link {
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s, box-shadow 0.3s, color 0.3s;
    text-decoration: none;
  }
  
  .login-button {
    background-color: transparent;
    color: #19d4ca;
  }
  
  .login-button:hover {
    background-color: #19d4ca;
    color: #fff;
    box-shadow: none;
  }
  
  .login-button:active {
    box-shadow: 0 0 10px #19d4ca;
  }
  
  .register-link {
    color: #ccc;
    background-color: transparent;
  }
  
  .register-link:hover {
    color: #fff;
  }
  
  .register-link:active {
    box-shadow: 0 0 10px #ccc;
  }
  
  @media (max-width: 480px) {
    .card {
      width: 100%;
      max-width: 300px;
    }
  }

  .table-container {
    max-height: 400px; /* Set a fixed maximum height as per your requirements */
    overflow-y: scroll;
    max-width: 100%;
    overflow-x: scroll;
  }
  